<template>
  <div class="p-2">
     <!-- Tabs -->
    <b-tabs 
      lazy
      active-tab-class="p-0 "
      pills
      nav-class="mb-0"
      active-nav-item-class="bg-info box-shadow-info border-info info"
    >
      <!-- Tab Pending -->
      <b-tab title="In Process" :title-link-class="[bgTabsNavs, 'sub-tab px-3']">
        <grid-in-process active :status="5" :idStandBy="1" :statusAccount="2" :tr="1" />
      </b-tab>
      <!-- Tab Done -->
      <b-tab title="Completed" :title-link-class="[bgTabsNavs, 'sub-tab px-3']">
        <grid-in-process :status="4" :idStandBy="3" :statusAccount="2" :tr="2" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
// Import Component
import GridInProcess from "../grids/GridInProcess.vue";

export default {
  components: {
    "grid-in-process": GridInProcess
  }
};
</script>